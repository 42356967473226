import { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { myContext } from '../../App';
import { Col, Row } from 'react-bootstrap';
import { MdOutlineEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

function BasicExample() {
  const [menus] = useContext(myContext);
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleSelect = () => {
    setExpanded(false);
  };


  return (
    <>
    <Navbar expand="lg" sticky='top' className="bg-body-tertiary" expanded={expanded}>
      <Container fluid className='navbar-container'>
        <Navbar.Brand className='navbar-brand-col' as={Link} to={'/'}>
          <img src='/assets/logo/Moatamad_Logo_W-O_TagLine++.png' alt="Logo" width={'100%'}/>
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="basic-navbar-nav">
          <div style={{ display: 'grid', width: "100%" }} className='navbar-grid-div'>
            <Row className='header-row'>
              <Col lg={12}>
                <h6><MdOutlineEmail className='email-header' />&nbsp;info@moatamadcars.com</h6>
                <h6><FaPhoneAlt size={'12px'} />&nbsp;600 562621</h6>
                <h6>
                 
                  <img src="https://c.tenor.com/Rwmnr_eC0sMAAAAC/tenor.gif" alt="" width={'60px'} height={'27px'} style={{objectFit:"cover"}}></img>
                  <img src='/assets/logo/Google_Rating_Star-01 1.png' width={'83px'} style={{height:"27px",position:"relative",left:"-8%"}} alt="Star Rating" /> 
                </h6>
                <h6 style={{ cursor: "pointer" }} className='contact-header-text'>
                  <Link to='/contact' style={{ textDecoration: "none", color: 'black' }}>
                    <MdOutlineEmail /> Contact
                  </Link>
                </h6>
              </Col>
            </Row>

            <Nav className="me-auto">
              
              {menus && menus.menus.map((item, index) => (
                item.children && item.children.length > 0? (
                  <NavDropdown title={item.menu_name_en} id={`basic-nav-dropdown-${index}`} key={index}>
                    {item.children && item.children.map((subItem, subIndex) => (
                      <NavDropdown.Item as={Link} onClick={handleSelect} to={subItem.slug} key={subIndex}>
                        {subItem.menu_name_en}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                ) : item.paddinglast ? <Nav.Link className={item.paddinglast} style={{padding:"5px 5px 5px 20px"}} as={Link} to={item.url} key={index} onClick={handleSelect}>
                {item.menu}
              </Nav.Link>: (
                  <Nav.Link className={item.menu_name_en === 'Careers' ? 'blog-nav-menu':null}  style={{padding:"5px 20px"}}  as={Link} to={item.slug} key={index} onClick={handleSelect}>
                    {item.menu_name_en}
                  </Nav.Link>
                )
              ))}
            </Nav>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>

  );
}

export default BasicExample;
